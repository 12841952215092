<template>
    <div class="coupon-section">
      <div v-if="popup">
        <WizardForm :popup="popup" @popup-changed="popupChanged"></WizardForm>
      </div>
      <div v-else class="text-center">
        <b-spinner></b-spinner>
      </div>
    </div>
  </template>
  
  <script>
  import WizardForm from './WizardForm'
  
  export default {
    components: {
      WizardForm,
    },
  
    data() {
      return {
        showColorModal: false,
        selectedMode: 'design',
        selectedTab: null,
        popup: null,
        loadingUpdate: false,
        loadingCoupon: false,
        loadingExportImage: false,
      }
    },
  
    mounted() {
      this.getCoupon()
    },
  
    methods: {
      getCoupon() {
        this.loadingCoupon = true
  
        this.$store
          .dispatch('coupon/find', this.$route.params.id)
          .then((popup) => {
            this.popup = popup
            // this.popup.sections = JSON.parse(
            //   JSON.stringify(this.$store.getters['coupon/popupOriginal'])
            // ).sections
            this.loadingCoupon = false
          })
          .catch(() => {
            this.loadingCoupon = false
          })
      },
  
      onTabClick(section) {
        this.selectedTab = section
      },
  
      update() {
        this.loadingUpdate = true
  
        this.$store
          .dispatch('coupon/update', this.popup)
          .then((res) => {
            this.loadingUpdate = false
            this.$router.push({ name: 'business.coupons.index' })
          })
          .catch((err) => {
            if (err.response.data.errors) {
              this.$refs.updateForm.setErrors(err.response.data.errors)
            }
            this.loadingUpdate = false
          })
      },
  
      popupChanged(popup) {
        this.popup = popup
      },
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .card-wrapper {
    max-width: 1024px;
  }
  
  .preview-container {
    display: flex;
    justify-content: center;
  }
  
  .coupon-section {
    .coupon-types {
      .card-img-top {
        max-width: 100%;
        width: 150px;
        margin: 0 auto;
        min-height: 150px;
      }
    }
  }
  
  .preview-actions {
    margin-top: 30px;
    border-top: 1px solid rgba(204, 204, 204, 0.5);
    padding-top: 30px;
    .btn {
      width: 150px;
    }
  }
  </style>
  